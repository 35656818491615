.showcase {
    position: relative;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;    
    padding-top: 70px;
    
}
.small-mobile  {
    width: 100%;
    height: auto;
  
}

.video-container {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 100%;
    
    
    overflow: hidden;
    background-color: rgb(0, 0, 0);
   /* background: url('../videos/Elokuvani.jpg') no-repeat center center/cover;
    */
    /*background: url('../images/kumLogoLila.png') no-repeat center center/cover; 
    */
    
}
.video-container video {
    min-width: 100%;
    min-height: 100%;
    
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.video-container:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(68, 68, 68);
    opacity: 0.12;

}
.video-content p {
    font-size: 22px;
}
.video-content {
    z-index: 2;

}

.btn {
    cursor: pointer;
    display: inline-block;
    padding: 10px 30px;
    font-size: large;
    background-color: rgb(247, 247, 247);
    border-radius: 5px;
    opacity: 0.8;
    margin-top: 25px;
    

}
.btn:hover {
    background-color: rgb(25, 24, 26);
    color:white;
}

@media screen and (max-width: 800px){
    .video-container {
        top: 50px;
        margin-bottom:  20px;
        
        
    }
   
   
   
}


@media (max-width: 340px) {
   .video-content h1 {
       font-size: 50px;
       font-weight: 100;
       padding: 10px;
   }
   .vali-otsikko {
       font-size: 50px;
   }

}
