* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
 /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.5;
  scroll-behavior: smooth;
  
}
/* Try to remove blue color from button */
button:focus {outline:0;}

a {
  color: rgb(210, 6, 218);
}

h1 {
  font-weight: 100;
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 15px;
}
p {
  /*font-weight: 370;*/
}
.container {
  width: 92%;
  max-width: 1200px;
  margin: auto;
}
.vali-otsikko {
  text-align: center;
  font-weight: 200;
  padding-top: 20px;
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.vali-otsikko :hover {
  cursor: pointer;
  opacity: 0.9;
}
.arrow-up {
  transition: all .25s ease-in-out;
  position: fixed;
  bottom: 20px;
  z-index: 2222;
  transform: translateX(0px);
  transition: transform 0.6s ease-in-out;
  right: 15px;
  display: inline-flex;
  cursor: pointer;
  
  
  

}
.arrow-up :hover {
  opacity: 1;
  color: rgb(230, 82, 250);
}
/*
.arrow-up {
  background-color: aqua;
  height: 7%;
  width: 5%;
  border-radius: 9px;
  

}
*/
.arrow-up i {
  /*
  right: 0%;
  left: 26%;
  top: 20%;
  */
  
  
  
  color: black;
  font-size: 30px;
  align-items: center;
}


@media(max-width: 800px) {
   .extra  {
    overflow-x: hidden !important; 
    
    
  
  }
  .open-button {
    right: -10px !important;
    opacity: 1 !important;
    display: inline;

  }
  .esiintyjavalitys video {
     width: 100% !important;
     background-color: black !important;
  }
}
/* esiintyjävalitys videot css */
.esiintyjavalitys {
  background-color:black;
  border-radius: 10px;


}
.esiintyjavalitys video {
  border-radius: 10px;
  width: 50%;
 
}
.esiintyja-social {
  
  position: relative;
  margin-bottom: 10px;
  left: -10px;
}
.esiintyja-social a {
  color: black;
  padding: 0px 5px;
  color: blueviolet;

  
}
button {
  border: none;
}


/* Chatbot buttons */

.open-button {
  background-color: rgb(178, 13, 243);
  color: white;
  padding: 0px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  opacity: 0.9;
  position: fixed;
  direction: inherit;
  bottom: 300px;
  right: 28px;
  width: 30px;
  height: 70px;
  transition-duration: 0.4s;

}
/*
.open-button:hover {
  background-color: rgb(60, 61, 61);
}
*/
.open-button h4 {
  transform: rotate(90deg);
  color:rgb(240, 240, 240);
}

@media only screen and (min-width: 1200px) and (max-width: 4000px) {
	.container {
		max-width: 1300px !important; 			 
		margin: auto;
		
				  
  }
  
	
		
}		