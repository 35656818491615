
.muskari-big-title {
  padding: 1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 33px;

}
.muskari-hero-text .muskari-small-title {
  font-size: 28px;
}
 /* MUSKARI INFO */
.muskari-infoa {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px auto; /* Center the section horizontally */
  max-width: 950px; 
  padding: 20px;

}
.muskari-hero-text p {
  font-family: sans-serif;
  line-height: 1.6;
}
.muskari-infoa p {
  font-family: sans-serif;
  line-height: 1.6;
}
.muskari-infoa-image {
  flex: 0 0 40%;
  shape-outside: circle(50%);
  shape-margin: 10px;
}

.muskari-infoa-image img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.muskari-infoa-image.left {
  margin-right: 20px;
}

.muskari-infoa-image.right {
  margin-left: 20px;
  
}

.muskari-infoa-text {
  flex: 1 0 50%;
}

/* MyComponent.css */

.image-text-section {
  display: flex; /* Use flexbox for layout */
  align-items: center; /* Vertically align items */
  padding: 20px;
  max-width: 1000px; /* Optional: Limit the width */
  margin: 0 auto; /* Optional: Center the section */
}

.image-container {
  flex: 1; /* Image takes up available space */
  margin-right: 20px; /* Add spacing between image and text */
}

.image-container img {
  width: 100%; /* Make image responsive */
  height: auto;
  border-radius: 10px; /* Optional: Add rounded corners */
}

.text-container {
  flex: 1; /* Text takes up available space */
}

.text-container h2 {
  margin-bottom: 10px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .image-text-section {
    flex-direction: column; /* Stack image and text on mobile */
  }

  .image-container {
    margin-right: 0; /* Remove right margin on mobile */
    margin-bottom: 20px; /* Add bottom margin to image on mobile */
  }
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .muskari-infoa {
    flex-direction: column;
  }
  /* Hide one of the images on mobile */
  .muskari-infoa-image.right {
    display: none;
  }

  .muskari-infoa-image {
    flex: 0 0 auto;
    width: 50%; /* Make the remaining image smaller */
    margin: 0px auto; /* Center the image */
    shape-outside: none;
  }

  .muskari-infoa-text {
    flex: 1 0 auto;
  }
}

/* Even smaller screens */
@media (max-width: 480px) {
    .muskari-infoa-image{
        width: 70%;
    }
    .hero-content {
      padding: 0px !important;
    }
    .muskari-big-title {
      padding: 0px !important;
    }
    .image-text-section {
      padding: 0px !important;
    }
}

.hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 3rem;
    overflow: hidden;
    flex-direction: column;
    /*background: linear-gradient(135deg, #b87fe9, #7e41f9); /* Liukuväri */

  }
  
  .hero-content {
    text-align: left;
    z-index: 10;
    max-width: 1100px;
    padding: 0px;
  }
  
  .hero-content h1 {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .funny-text {
    margin-top: 8rem;
    font-size: 22px;
    margin-bottom: 10rem;
    background-color: rgb(226, 43, 235);
    
  }

  @media (max-width: 768px) {
    .funny-text {
        font-size: 14px;
    }
  }

  @media (max-width: 480px) {
    .hero {
      padding: 5px; /* Further adjust padding for very small screens */
    }
    .funny-text {

        width: 100%;
        font-size: 14px;
        margin-bottom: 6rem !important;
        background-color: white
    }
    .image-4 {
      margin-top: -9rem !important;
    }
  }

  @media (max-width: 420px) {
    .hero {
      padding: 2px; /* Further adjust padding for very small screens */
      margin-top: 3.4rem;
      padding-bottom: 0.3rem;
    }
    .funny-text {
        margin-top: 7.3rem;
        width: 100%;
        font-size: 12px;
        padding-bottom: 1.5rem;
        background-color: white
    }
    
  }
  