/* ContentPage.css */

  .hero-section {
    text-align: center;
    padding: 40px 20px;
    background-color: #f4f4f4;
    margin-bottom: 30px;
  }
  

  
  .article {
    margin-bottom: 30px;
  }
  
  .article h2 {
    margin-bottom: 10px;
    color: #b300ff;
  }
  .image-1 {
    width: 40%;
    border-radius: 10%;
  }
  .image-2 {
    width: 30%;
    border-radius: 50%;
    margin-top: 0rem;
  }  
  .image-3 {
    width: 40%;
    margin-top: -4rem;
  }
  .image-4 {
    width: 55%;
    margin-top:  -13rem;
  }


.content-page {

    font-family: sans-serif;
    line-height: 1.6;
    
    color: #333;
    align-items: center; /* Lisätty rivi */
    gap: 20px;
  

  }
  
  .article-section {
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .article {
    margin-bottom: 30px;
  }
  
  .article-content {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }
  
  .article[imageFirst="false"] .article-content {
    flex-direction: row-reverse; /* Text first, image second */
  }
  
  .image-left,
  .image-right {
    max-width: 350px;
    border-radius: 10px;
  }
  
  .image-right {
    border-radius: 50%;
  }
  
  .text-content h2 {
    margin-bottom: 10px;
  }
  
  .text-content {
    width: 100%;
  }
  
  /* Mobile-specific styles */
  @media (max-width: 768px) {
    .article-content {
      flex-direction: column; /* Image top, text bottom */
    }
  
    .image-left,
    .image-right {
      max-width: 100%;
    }
      .image-1 {
        width: 100%;
        border-radius: 10%;
      }
      .image-2 {
        width: 100%;
        border-radius: 50%;

      }  
      .image-3 {
        width: 100%;
      }
      .image-4 {
        width: 100%;

      }
  }